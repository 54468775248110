import React, { Component } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"

export default class PostLayout extends Component {
  render() {
    const { markdownRemark } = this.props.data
    const {location} = this.props;
    return (
      <Layout location={location}>
        <div>
          <h1>{markdownRemark.frontmatter.name}</h1>
          <div
            dangerouslySetInnerHTML={{
              __html: markdownRemark.html,
            }}
          />
        </div>
      </Layout>
    )
  }
}

export const query = graphql`
  query PostQuery($slug: String!) {
    markdownRemark(frontmatter: { path: { eq: $slug } }) {
      html
      frontmatter {
        name
        date
        path
      }
    }
  }
`
